import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const threeBoxSectionConnectors = ({ title }) => {
    return (
        <div className='container-1'>
            <div className='row justify-content-center pt-2'>
                <Fragment>
                    <div className='col-lg col-md-5 mx-3 col-sm-8 col-10 mt-1 connector-box-template pricing-team-color'>
                        <div className='pt-3 pb-4'>
                            <h3 className='mx-auto f-20 lh-29 text-center'>
                                Docker to test the connector
                            </h3>

                            <div className='mb-3'>
                                <div className='roll-button pt-1 mt-5 text-center pb-4'>
                                    <a
                                        className='bg-red text-white mr-0 f-14'
                                        href='/apache-kafka-docker/'>
                                        <span data-hover='Get started'>Get started</span>
                                    </a>
                                </div>
                                <div className='text-center'>
                                    <p className='pt-1'>
                                        Test in our pre-configured Lenses & Kafka sandbox packed
                                        with connectors
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg col-md-5 mx-3 col-sm-8 col-10 mt-1 connector-box-template mobile-margin-top'>
                        <div className='pt-3 pb-4'>
                            <h3 className='f-20 lh-29 text-center'>Use Lenses with your Kafka</h3>
                            <div className='mb-3'>
                                <div className='roll-button pt-1 mt-5 text-center pb-4'>
                                    <a
                                        className='primary-text border-red mr-0 f-14'
                                        href='//portal.lenses.io/register/'>
                                        <span data-hover='Download'>Download</span>
                                    </a>
                                </div>
                                <div className='text-center'>
                                    <p>
                                        Manage the connector in Lenses against your Kafka and data.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg col-md-5 mx-3 col-sm-8 col-10 mt-1 connector-box-template mobile-margin-top'>
                        <div className='pt-3 pb-4'>
                            <h3 className='f-20 lh-29 text-center'>{title}</h3>
                            <div className='mb-3'>
                                <div className='roll-button text-center py-4'>
                                    <a
                                        className='primary-text border-red mr-0 f-14'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        href='//github.com/lensesio/stream-reactor'>
                                        <span data-hover='Download in Git'>Download in Git</span>
                                    </a>
                                </div>
                                <div className='text-center'>
                                    <p>Download the connector the usual way from GitHub</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            </div>
        </div>
    )
}

export default threeBoxSectionConnectors

threeBoxSectionConnectors.propTypes = {
    title: PropTypes.string,
}
